<template>
    <div class="search">
        <div class="flex">
            <!-- <h3>Business</h3> -->
        </div>

        <div class="right" @click="$router.push('/orders/cart')">
            <Button @click="$router.push('/users/add')" :style="{ color: '#fff', backgroundColor: '#159661', width: '150px', borderRadius: '50px', padding: '10px' }" text="Check Cart<i class='fa fa-shopping-cart' style='margin-left: 10px; font-size: 16px; font-weight: bold'></i>"/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    components: {
        Button
    }
}
</script>

<style scoped>
    .search {
        display: flex;
        width: 95%;
        margin: 2% auto;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .fake-input {
        background: #fff;
        /* padding: 10px; */
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 350px;
        padding: 0 10px;
        color: #ccc;
        margin-left: 20px;
    }

    input {
        padding: 10px;
        font-size: 15px;
        border: none;
        font-family: inherit;
        outline: none;
    }

    .fa-bell {
        font-size: 20px;
        cursor: pointer;
    }
</style>