<template>
  <div class="order">
        <div class="main-header">
            <dashboard-header page="My order"></dashboard-header>
        </div>
        <div class="header">
            <Header/>
        </div>

        <router-view id="router"></router-view>

        <div class="bottom flex">
            <div class="options">
                <div @click="card = 1">Business card</div>
                <div @click="card = 2">Identity card</div>
                <div @click="card = 3">Sticker</div>
                <div @click="card = 4">Counter Tops</div>
            </div>

            <div class="card">
                <Card :card="card"/>
            </div>
        </div>
  </div>
</template>

<script>
import DashboardHeader from '@/components/DashboardHeader.vue'
import Header from './components/Header.vue'
import Card from './components/Card.vue'

export default {
    components: {
        DashboardHeader,
        Header,
        Card
    },
    data(){
        return {
            card: 1
        }
    }
}
</script>

<style scoped>
    .order {
        padding: 0 10px;
    }

    .header {
      margin-top: 3.5rem;
  }

  .bottom {
      padding: 10px;
  }

  .bottom > div {
      padding: 20px;
      background: #fff;
      height: 500px;
      border-radius: 20px;
      box-shadow: 0 0 6px 3px #9797971c;
  }

  .bottom > div:first-child {
      flex: 2.8;
      margin-right: 15px;
      padding: 2.5rem;
  }

  .bottom > div:last-child {
      flex: 4;
  }

  .options > div {
      padding: 1.5em;
      border: 1px solid #159661;
      border-radius: 20px;
      margin-bottom: 20px;
      padding-left: 3rem;
      font-size: 18px;
      font-weight: 700;
      color: #159661;
      box-shadow: 0 0 6px 5px #9797971c;
      cursor: pointer;
      transition: background 0.1s;
  }

  .options > div:hover {
      background-color: #159661;
      color: #fff;
  }
</style>