<template>
  <div class="display flex">
      <div class="card">

      </div>

      <div class="info" v-if="card === 1">
          <h3>Linkmeup business card</h3>

          <div class="details">
              <p>Specifications: </p>
              <p>Dimension: H2 x W4</p>
              <p>Quantity: 200 pieces</p>
              <p>Amount: $50.00</p>
          </div>
          <div class="btn-spot">
            <Button @click="$router.push('/orders/directory')" :style="{ color: '#fff', backgroundColor: '#159661', width: '150px', borderRadius: '50px', padding: '10px' }" text="Order Now"/>
          </div>
      </div>
      <div class="info" v-if="card === 2">
          <h3>Linkmeup Identity card</h3>

          <div class="details">
              <p>Specifications: </p>
              <p>Dimension: H2 x W4</p>
              <p>Quantity: 200 pieces</p>
              <p>Amount: $50.00</p>
          </div>
          <div class="btn-spot">
            <Button @click="$router.push('/orders/directory')" :style="{ color: '#fff', backgroundColor: '#159661', width: '150px', borderRadius: '50px', padding: '10px' }" text="Order Now"/>
          </div>
      </div>
      <div class="info" v-if="card === 3">
          <h3>Linkmeup Sticker</h3>

          <div class="details">
              <p>Specifications: </p>
              <p>Dimension: H2 x W4</p>
              <p>Quantity: 200 pieces</p>
              <p>Amount: $50.00</p>
          </div>
          <div class="btn-spot">
            <Button @click="$router.push('/orders/directory')" :style="{ color: '#fff', backgroundColor: '#159661', width: '150px', borderRadius: '50px', padding: '10px' }" text="Order Now"/>
          </div>
      </div>
      <div class="info" v-if="card === 4">
          <h3>Counter Tops</h3>

          <div class="details">
              <p>Specifications: </p>
              <p>Dimension: H2 x W4</p>
              <p>Quantity: 200 pieces</p>
              <p>Amount: $50.00</p>
          </div>
          <div class="btn-spot">
            <Button @click="$router.push('/orders/directory')" :style="{ color: '#fff', backgroundColor: '#159661', width: '150px', borderRadius: '50px', padding: '10px' }" text="Order Now"/>
          </div>
      </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    props: {
        card: Number
    },
    components: {
        Button
    }
}
</script>

<style scoped>
    .flex {
        align-items: center;
        height: 100%;
        padding: 20px;
    }

    .card {
        height: 400px;
        border: 1px solid #159661;
        width: 250px;
        border-radius: 30px;
    }

    .info {
        width: 200px;
    }

    .info h3 {
        margin-bottom: 20px;
    }

    .btn-spot {
        text-align: center;
    }

    .details {
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 15px;
    }
</style>